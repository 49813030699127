<template>
  <DialogTitle :as-child="asChild" :class="styles({ class: props.class })">
    <slot>{{ title }}</slot>
  </DialogTitle>
</template>

<script lang="ts" setup>
import { DialogTitle } from "radix-vue";

const props = defineProps<{
  class?: any;
  asChild?: boolean;
  title?: string;
}>();

const styles = tv({
  base: "text-lg font-semibold leading-none tracking-tight",
});
</script>
