<template>
  <DialogRoot>
    <slot name="trigger"></slot>

    <DialogPortal>
      <DialogOverlay
        class="data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn fixed inset-0 z-50 bg-black/50 backdrop-blur-sm"
      />

      <slot name="content">
        <DialogContent
          class="fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg outline-none sm:rounded-lg md:w-full"
          :class="maxWClass"
        >
          <DialogHeader
            v-if="title || description"
            :title="title"
            :description="description"
          />
          <slot />
        </DialogContent>
      </slot>
    </DialogPortal>
  </DialogRoot>
</template>

<script setup lang="ts">
import {
  DialogRoot,
  DialogPortal,
  DialogOverlay,
  DialogContent,
} from "radix-vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    maxWidth?:
      | "xs"
      | "sm"
      | "md"
      | "lg"
      | "xl"
      | "2xl"
      | "3xl"
      | "4xl"
      | "5xl"
      | "6xl"
      | "7xl";
  }>(),
  { maxWidth: "lg" },
);

const maxWClass = computed(() => {
  switch (props.maxWidth) {
    case "xs":
      return "max-w-xs";
    case "sm":
      return "max-w-sm";
    case "md":
      return "max-w-md";
    case "lg":
      return "max-w-lg";
    case "xl":
      return "max-w-xl";
    case "2xl":
      return "max-w-2xl";
    case "3xl":
      return "max-w-3xl";
    case "4xl":
      return "max-w-4xl";
    case "5xl":
      return "max-w-5xl";
    case "6xl":
      return "max-w-6xl";
    case "7xl":
      return "max-w-7xl";
  }
  return "max-w-lg";
});
</script>
