<template>
  <div :class="styles({ class: props.class })">
    <slot>
      <slot name="title">
        <DialogTitle v-if="title" :title="title" />
      </slot>
      <slot name="description">
        <DialogDescription v-if="description" :description="description" />
      </slot>
    </slot>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  class?: any;

  title?: string;
  description?: string;
}>();

const styles = tv({
  base: "flex flex-col space-y-1.5 text-center sm:text-left",
});
</script>
